<template>
  <v-card @mouseout="showSettings = !showSettings" @mouseover="showSettings = !showSettings" class="mt-2" flat outlined> 
    <v-card-text>
      <v-row justify="space-between" align="center" class="px-2">
        <v-col class="body-2 px-0 py-2 text-truncate">
          <div class="custom-box" v-if="!custom">
            <span class="font-weight-bold">{{ data.property }}</span> <span class="ml-1">{{ data.value }}</span>
          </div>
          <div class="custom-box" v-else>
            <span class="font-weight-bold">{{ getProperty }}</span> <span class="ml-1">{{ getContent }}</span>
          </div>
        </v-col>
        <v-col v-if="!data.include" cols="1" class="pa-0 text-right">
          <v-icon size="20">mdi-eye-off</v-icon>
        </v-col>
        <v-col v-show="showSettings" cols="1" class="pa-0 text-right">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="290">
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top transition="fade-transition">
                <template v-slot:activator="{ on: tooltip, attrs }">
                  <v-btn color="primary" icon v-bind="attrs"
                    v-on="{ ...tooltip, ...dialog }">
                    <v-icon v-if="!custom" size="20">mdi-cog</v-icon>
                    <v-icon v-else size="20">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-if="!custom">{{ $tc('blog.seo.TAGSETTINGS', 1)  }}</span>
                <span v-else>{{ $t('EDIT')  }}</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-title class="headline" v-if="!custom">
                <v-row class="pt-4">
                  <v-col class="pa-0 pl-3">
                    <h1 class="mt-1 subtitle-2 font-weight-regular">
                    {{ $tc('blog.seo.INCLUDETAG', 1) }}</h1> 
                  </v-col>
                  <v-col cols="2 pa-0">
                    <div>
                      <v-switch
                        dense
                        :ripple="false"
                        class="float-right mt-0"
                        v-model="data.include"
                        inset>
                      </v-switch>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider v-if="!custom"></v-divider>
              <v-card-text class="mt-2" v-if="data.include">
                <slot name="form">
                  <v-row>
                    <v-col>
                      <h1 class="mt-1 subtitle-2 font-weight-regular">
                      {{ $tc('blog.seo.TAGVALUE', 1) }}<v-icon>mdi-information-outline</v-icon></h1> 
                    </v-col>
                  </v-row>
                  <v-text-field
                  dense
                    class="py-2"
                    hide-details
                    clearable
                    outlined
                    v-model="data.value">
                  </v-text-field>
                  <h1 class="mt-1 subtitle-2 font-weight-regular">{{ $tc('blog.seo.TAGPREVIEW', 1) }}</h1> 
                  <code>{{ exampleCode }}</code>
                </slot>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                  
                <v-btn small @click="dialog = false" fab outlined>
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
                <v-btn small fab @click="dialog = false" color="primary">
                  <v-icon dark small>mdi-check</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col v-show="showSettings && custom" cols="1" class="pa-0 text-right ml-2">
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on: tooltip, attrs }">
              <v-btn v-bind="attrs"
                v-on="tooltip" color="primary" icon @click="removeMetaTag()">
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span v-if="!custom">{{ $tc('blog.seo.TAGSETTINGS', 1)  }}</span>
            <span v-else>{{ $t('DELETE')  }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "meta-tags-item",
  props: {
    data: Object,
    custom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSettings: false,
      dialog: false
    }
  },
  computed: {
    exampleCode () {
      return `<meta property="${this.data.property}" content="${this.data.value}">`
    },
    getProperty () {
      const temp = this.data.value.split(/(?:'|")+/)
      return temp[1]
    },
    getContent () {
      const temp = this.data.value.split(/(?:'|")+/)
      return temp[3]
    }
  },
  methods: {
    removeMetaTag() {
      this.$emit('remove', this.data)
    }
  },
}
</script>

<style scoped>
.custom-box{
  overflow: hidden;
  max-width: 300px;
  height: 20px;
}
</style>
